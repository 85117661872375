<template>
  <div>
    <vs-popup class="popup-content-no-padding sm:popup-w-70" :title="`Pilih Data Untuk: ${titleParam}`" :active="isActive" v-on:update:active="emitModalIsActive">
      <div class="w-full h-screen-60 flex flex-col p-2 overflow-hidden">
        <!--search-->
        <div class="flex-none flex flex-col mb-2">
          <div class="flex-none flex flex-row justify-end space-x-6">
            <div class="flex flex-row items-center cursor-pointer hover:text-primary" :class="{'opacity-50 pointer-events-none': loading}" @click="refresh">
              <feather-icon icon="RefreshCwIcon" svgClasses="h-4 w-4" class="p-2"/>
              <span class="text-sm">Refresh</span>
            </div>
            <input type="text" class="placeholder-grey-dark border border-solid d-theme-border-grey-light text-sm p-3 w-64 rounded" placeholder="Search" v-model="searchTmp" @keyup.enter="search = searchTmp; $event.target.select()" @focus="$event.target.select()"/>
          </div>
        </div>
        <!--loading-->
        <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !loading }"/>
        <div class="flex-1 overflow-auto border border-solid d-theme-border-grey-light">
          <table class="table-auto table-sticky border-collapse w-full">
            <thead>
              <tr class="d-theme-dark-bg">
                <th v-for="(column, index) in tableColumns" :key="index" class="border border-solid d-theme-border-grey-light p-2 text-xs text-center whitespace-no-wrap">
                  {{ column.toUpperCase() }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in itemsFiltered" :key="index" @click="onItemClicked(item)" class="cursor-pointer hover-bg-primary-transparent-10">
                <td v-for="(column, index) in tableColumns" :key="index" class="text-sm p-2 border border-solid d-theme-border-grey-light text-left">
                  {{ item[column] }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import _ from 'lodash'
import ReportRepository from '@/repositories/reports/report-repository'

export default {
  name: 'LinkedDataBrowser',
  props: ['isActive', 'idParameter', 'titleParam'],
  computed: {
    itemsFiltered () {
      const search = this.search
      const items = this.items
      if (search) {
        return _.filter(items, (item) => {
          const keys = _.keys(item)
          const searchToLowerCase = search.toLowerCase()
          let isExist = false
          _.each(keys, key => {
            if (item[key] && item[key].toString().toLowerCase().includes(searchToLowerCase)) {
              isExist = true
            }
          })
          return isExist
        })
      }
      return items
    },
    tableColumns () {
      if (this.items.length < 1) return []
      const item = this.items[0]
      return _.keys(item)
    }
  },
  watch: {
    isActive (active) {
      if (active) {
        if (this.lastIdParameter !== this.idParameter) {
          this.search = null
          this.searchTmp = null
          this.key_field = null
          this.alias_field = null
          this.items = []
          this.getData()
        }
      }
    }
  },
  data () {
    return {
      loading: false,
      searchTmp: null,
      search: null,
      lastIdParameter: null,
      key_field: null,
      alias_field: null,
      items: []
    }
  },
  methods: {
    refresh () {
      this.search = this.searchTmp
      this.getData()
    },

    getData () {
      this.loading = true

      const idParameter = this.idParameter
      ReportRepository.getParameterLinkedData(idParameter)
        .then(response => {
          this.key_field = response.data.data.key_field
          this.alias_field = response.data.data.alias_field
          this.items = response.data.data.items
          this.lastIdParameter = this.idParameter
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading = false
        })
    },

    onItemClicked (item) {
      const data = {
        key_field_value: item[this.key_field],
        alias_field_value: this.alias_field ? item[this.alias_field] : null
      }
      this.emitSelected(data)
      this.emitModalIsActive(false)
    },

    resetData () {
      Object.assign(this.$data, this.$options.data.call(this))
    },

    emitSelected (data) {
      this.$emit('selected', data)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>

<style scoped>
.table-sticky thead {
  position: sticky;
  top: -1px;
  z-index: 1;
}
.table-sticky tfoot {
  position: sticky;
  bottom: -1px;
  z-index: 1;
}
</style>
